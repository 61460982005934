import Home from "./Home";
import TabloHavaleFarda from "./TabloHavaleFarda";
import TabloHavaleRooz from "./TabloHavaleRooz";

const pages = [
  {
    url: "/",
    title: "خانه",
    backURL: "/",
    component: <Home />,
  },
  {
    url: "/tablohavalefarda",
    title: "تابلو حواله فردایی",
    backURL: "/",
    component: <TabloHavaleFarda />,
    type: "tablo",
  },
  {
    url: "/tablohavalerooz",
    title: "تابلو حواله روز",
    backURL: "/",
    component: <TabloHavaleRooz />,
    type: "tablo",
  },
];

export const getPageByUrl = (url) => {
  return pages.find((item) => item.url === url);
};

export const getAllPages = () => {
  return pages;
};
