import dateFormat from "dateformat";
import AlarmOutlinedIcon from "@mui/icons-material/AlarmOutlined";
import { useAtom } from "jotai";
import { accountAtom } from "../atoms/accountAtom";
import PanToolIcon from "@mui/icons-material/PanTool";

function LafzTablo({ liveTablo, liveTabloOld, item, onClick, now, onVolumeClicked }) {
  const [account, setAccount] = useAtom(accountAtom);

  const arr = [[1], [1, 2], [1, 2, 3]];

  return (
    <div
      onClick={onClick}
      key={`lafz-${item.dir}-${item.seq}`}
      className={`${
        liveTablo.find((m) => m.seq === item.seq) &&
        !liveTabloOld.find((m) => m.seq === item.seq)
          ? `slide-in-${item.dir === "buy" ? "right" : "left"} `
          : " "
      }   
				  ${
            !liveTablo.find((m) => m.seq === item.seq)
              ? `opacity-0 h-0 ${
                  item.dir === "sell" ? "-" : ""
                }translate-x-full `
              : "opacity-100 translate-x-0 h-full "
          }     
		  py-0 px-2 w-full mb-[30px] relative rounded-t-xl bg-[#4d5560] bg-opacity-50 transition-all duration-500 ease-in-out  flex flex-col text-white`}
    >
      {item.timeStamp && (
        <div className="w-full h-[5px] bg-gray-400 flex justify-end rounded-b-xl">
          <div
            className="h-full rounded-b-xl bg-[#1cb2e8]"
            style={{
              width: `${
                (60 -
                  Math.round(
                    (now.getTime() - Date.parse(item.timeStamp)) / 1000
                  )) *
                1.66
              }%`,
            }}
          ></div>
        </div>
      )}
      <div className="flex flex-row justify-between items-center">
        <span
          className={`${
            item.dir === "buy" ? "text-green-400 " : "text-red-400 "
          } text-sm`}
        >
          {`${item.dir === "buy" ? "خرید" : "فروش"}`}
        </span>
        <p
          className="text-xs text-gray-400 flex flex-row items-center gap-1"
          dir="ltr"
        >
          <span>{`${dateFormat(item.timeStamp, "H:MM TT")}`}</span>
          <AlarmOutlinedIcon sx={{ width: "16px" }} />
        </p>

        {item.issuer === account?.username && (
          <span className="text-[#6ceafa]">
            <PanToolIcon sx={{ width: "15px" }} />
          </span>
        )}
      </div>

      <span className="flex flex-row gap-1 items-center">
        <span className="tracking-wide">
          {`${item.price?.toLocaleString() || ""}`}
        </span>
        <span className="text-xs text-slate-300">{"تومان"}</span>
        {item.issuer === account?.username && (
          <span className="flex justify-center items-center gap-1 text-sm bg-gray-600 px-1 rounded">
            <span>{`${item.volume}`}</span>
            <span className="text-xs text-gray-300">{"کیلو"}</span>
          </span>
        )}
      </span>

      {item.volume > 0 && item.issuer !== account?.username && (
        <div className="absolute h-[25px] w-full -bottom-[25px] left-0 flex flex-row-reverse justify-between gap-1">
          {arr[item.volume - 1].map((kilo) => (
            <div
              onClick={() => onVolumeClicked(kilo)}
              className="flex justify-center items-center rounded-b-xl bg-[#40576e] bg-opacity-100 w-full"
            >
              {`${kilo}`}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default LafzTablo;
