import {
  TextField,
  InputAdornment,
  Paper,
  IconButton,
  CircularProgress,
  Alert,
} from "@mui/material";
import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  LockOutlined,
  PersonOutline,
  Visibility,
  VisibilityOff,
  VpnKeyOutlined,
} from "@mui/icons-material";
import UserService from "../services/UserService";
import { useAtom } from "jotai";
import { authTokenAtom } from "../atoms/authTokenAtom";
import TextBox from "./TextBox"
import ChangePasswordDialog from "./ChangePasswordDialog";

function Login() {
  const theme = useTheme();
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  const [submitting, setsubmitting] = useState(false);
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [invalidPassword, setInvalidPassword] = useState(false)
  const [systemError, setSystemError] = useState(false)
  const [userBlank, setUserBlank] = useState(false)
  const [passwordBlank, setPasswordBlank] = useState(false)
  const [authToken, setAuthToken] = useAtom(authTokenAtom)

  const [shouldChangePassword, setShouldChangePassword] = useState(false)

  const submitLogin = async () => {
    if (submitting || !username || !password)
    {
        setUserBlank(!username)
        setPasswordBlank(!password)
        return
    }

    setUserBlank(false)
    setPasswordBlank(false)

    setInvalidPassword(false)
    setSystemError(false)

    setsubmitting(true)

    

    try{
        const result = await UserService.signin({username, password})
        setsubmitting(false)
        if (result.data.status === "OK"){
            const token = result.data.token
            if (!token)
            {
                throw new Error("Token undefined!")
            }
            
            localStorage.setItem("authToken", token )
            setAuthToken(token)

            // if (!result.data.changePassword)
            // {
            //   setShouldChangePassword(true)
            // }

        }else{
            setInvalidPassword(true)
        }
    }catch(err)
    {
        setsubmitting(false)
        setSystemError(true)
        console.log(err)
    }
    

  }

  return (
    <div className="w-full h-screen flex flex-col justify-center items-center">
      <Paper elevation={8}>
        <div className="flex flex-col gap-8 items-center justify-center px-[50px] py-[30px]">
          <div className="flex flex-col items-center justify-center gap-2 mb-[20px]">
            <div
              className="rounded-full p-2 text-orange-900"
              style={{ background: `${theme.palette.secondary.main}` }}
            >
              <LockOutlined />
            </div>
            <h1 className="text-[18px]">ورود به سیستم</h1>
          </div>

          <div className="flex flex-row items-end justify-center">
            <TextBox
              id="username"
              label="نام کاربری"
              InputAdornment
              autoComplete={"off"}
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="end">
                      <PersonOutline
                        style={{
                          color: theme.palette.primary.light,
                          width: "22px",
                        }}
                      />
                    </InputAdornment>
                  ),
                },
              }}
              variant="standard"
              value={username}
              onChange={(event) => {setUsername(event.target.value); setUserBlank(!event.target.value) }}
              disabled={submitting}
              error={userBlank}

            />
            <div className="w-[10px]"></div>
          </div>

          <div className="flex flex-row items-end justify-center">
            <TextBox
              id="password"
              label="رمز عبور"
              type={showPassword ? "text" : "password"}
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="end">
                      <VpnKeyOutlined
                        style={{
                          color: theme.palette.primary.light,
                          width: "20px",
                        }}
                      />
                    </InputAdornment>
                  ),
                },
              }}
              variant="standard"
              value={password}
              onChange={(event) => {setPassword(event.target.value); setPasswordBlank(!event.target.value)}}
              disabled={submitting}
              error={passwordBlank}
            />
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              onMouseUp={handleMouseUpPassword}
              edge="end"
              style={{ width: "10px" }}
              disabled={submitting}
            >
              {showPassword ? (
                <VisibilityOff style={{ width: "22px" }} />
              ) : (
                <Visibility style={{ width: "22px" }} />
              )}
            </IconButton>
          </div>

          <div
            className={`w-full rounded-full flex flex-row justify-center items-center gap-2 h-[50px] mt-[20px]`}
            style={{
              background: `${theme.palette.primary.main}`,
              color: `${theme.palette.primary.contrastText}`,
            }}
            onClick={() => submitLogin()}
            
          >
            {submitting ? <span>
                <CircularProgress disableShrink color="white" style={{color:`white`}} size={30}/>
            </span> : <span>ورود</span>}
          </div>

          <div className={`${invalidPassword ? " " : "hidden "}`}>
            <Alert severity="error">
                <p className="text-sm pr-2">
                    نام کاربری یا رمزعبور اشتباه می باشد
                </p>
            </Alert>
          </div>

          <div className={`${systemError ? " " : "hidden "}`}>
            <Alert severity="error">
                <p className="text-xs pr-2">
                    <p className="mb-2">
                        مشکل در ارتباط با سرور :
                    </p>
                    <p>

                    </p>
                      لطفا اینترنت خود را مجددا کنترل نمایید 
                </p>
            </Alert>
          </div>

        </div>
      </Paper>


      <ChangePasswordDialog open={shouldChangePassword} currentPassword={password} closeDisable={true} onClose={() => setShouldChangePassword(false)} />

      
    </div>
  );
}

export default Login;
