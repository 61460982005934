import {
  TextField,
  Input,
  InputLabel,
  InputAdornment,
  FormControl,
  Paper,
  IconButton,
  Icon,
  Button,
  CircularProgress,
  Alert,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  AccountCircleOutlined,
  Key,
  Lock,
  LockOutlined,
  PersonOutline,
  Visibility,
  VisibilityOff,
  VpnKey,
  VpnKeyOutlined,
} from "@mui/icons-material";
import UserService from "../services/UserService";
import { useAtom } from "jotai";
import { authTokenAtom } from "../atoms/authTokenAtom";
import TopBar from "./TopBar";
import BottomBar from "./BottomBar";
import Main from "./Main";
import OfflineAlert from "./OfflineAlert";

function Layout() {
  const theme = useTheme();

  return (
    <div className="w-full h-screen overflow-hidden flex flex-col gap-0 justify-between">
      <div>
        <TopBar/>
        <OfflineAlert/>
      </div>

      <Main/>  

    </div>
  );
}

export default Layout;
