
let subscribers = []

export const subscribeForMessage = (type , callback, id) => {
    if (!id)
    {
        id = "1"
    }

    if (!subscribers.find(e => e.type === type && e.id === id))
    {
        return subscribers.push({type: type, callback: callback, id: id})
    }else
    {
        return -1
    }
}

export const endSubscription = (index) => {
    if (index > 0)
    {
        subscribers.splice(index - 1 , 1)
    }
}

export const messageRecieved = (msg) =>
{
    subscribers.forEach( subscriber => {
        if (subscriber.type === msg.type)
        {
            subscriber.callback(msg)
        }
    })
}