import { useAtom } from "jotai";
import { urlAtom } from "../atoms/urlAtom";
import { useEffect, useRef, useState } from "react";
import { endSubscription, subscribeForMessage } from "../utils/subscriber";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Numpad from "../components/Numpad";
import { sendMessage } from "../utils/publisher";
import { socketAtom } from "../atoms/socketAtom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import dateFormat from "dateformat";
import LafzTablo from "../components/LafzTablo";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { accountAtom } from "../atoms/accountAtom";
import { liveTabloTHFAtom } from "../atoms/liveTabloTHFAtom";
import { myLafzTHFAtom } from "../atoms/myLafzTHFAtom";
import MyLafz from "../components/MyLafz";


const CURRENT_TABLO = "THF"

function TabloHavaleFarda() {
  const [url, setUrl] = useAtom(urlAtom);

  const [socket, setSocket] = useAtom(socketAtom);

  const [account, setAccount] = useAtom(accountAtom)

  const [buyOfferText, setBuyOfferText] = useState("");
  const [buyOfferValue, setBuyOfferValue] = useState(0);

  const [sellOfferText, setSellOfferText] = useState("");
  const [sellOfferValue, setSellOfferValue] = useState(0);
  const [numpadBuy, setNumpadBuy] = useState(false);
  const [numpadSell, setNumpadSell] = useState(false);

  const [now, setNow] = useState(new Date());

  const [expandBottomBar, setExpandBottomBar] = useState(true)

  const [clickedItem, setClickedItem] = useState(null)

  const lastBuyRef = useRef(null);

  useEffect(() => {
    const subLafz = subscribeForMessage(
      "responseLafz",
      responseLafzRecieved,
      "TabloHavaleFarda"
    );

    const subTablo = subscribeForMessage(
      "updateTabloTHF",
      updateTabloTHFRecieved,
      "TabloHavaleFarda"
    );

    const interval = setInterval(() => {
      setNow(() => new Date());
    }, 1000);

    return () => {
      endSubscription(subLafz);
      endSubscription(subTablo);
      clearInterval(interval);
    };
  }, []);

  const [errorMessage, setErrorMesssage] = useState("");
  const responseLafzRecieved = (msg) => {
    const { status, error } = msg;
    if (status === "FAILED") {
      setErrorMesssage(error);
    }
  };

  const [liveTablo, setLiveTablo] = useAtom(liveTabloTHFAtom)
  const [liveTabloOld, setLiveTabloOld] = useState([]);
  const [liveTabloAll, setLiveTabloAll] = useState([]);  

  const updateTabloTHFRecieved = (msg) => {
    setLiveTablo((prev) => msg.liveTablo);
    setLiveTabloOld((prev) => msg.liveTabloOld);
    setLiveTabloAll((prev) => mergeArray(msg.liveTablo, msg.liveTabloOld));
  };

  useEffect( () => {

    if (clickedItem)
        {
            if (!liveTablo.find(r => r.seq === clickedItem.item.seq && r.volume >= clickedItem.kilo ))
            {
                setClickedItem(prev => null)
            }
        }

  }, [clickedItem, liveTablo])

  const mergeArray = (arr1, arr2) => {
    const merge = [...arr1];
    for (const item of arr2) {
      if (!merge.find((m) => m.seq === item.seq)) {
        merge.push(item);
      }
    }
    return merge.sort((a, b) => a.seq - b.seq);
  };

  const buyOfferTextChanged = (str) => {
    let newText = str.replace(/\D/g, "");
    if (newText.length > 5) {
      newText = newText.substr(0, 5);
    }
    if (!newText || newText.trim() === "") {
      setBuyOfferText("");
      setBuyOfferValue(0);
      return;
    }

    const num = parseInt(newText);
    setBuyOfferValue(num * 1000);
    setBuyOfferText(num.toLocaleString());
  };

  const sellOfferTextChanged = (str) => {
    let newText = str.replace(/\D/g, "");
    if (newText.length > 5) {
      newText = newText.substr(0, 5);
    }
    if (!newText || newText.trim() === "") {
      setSellOfferText("");
      setSellOfferValue(0);
      return;
    }

    const num = parseInt(newText);
    setSellOfferValue(num * 1000);
    setSellOfferText(num.toLocaleString());
  };

  const [showBuyConfirm, setShowBuyConfirm] = useState(false);
  const [buyVolume, setBuyVolume] = useState(1);

  const buyClicked = () => {
    if (buyOfferText.length < 6) {
      setNumpadBuy(true);
      return;
    }
    setNumpadBuy(false);
    setShowBuyConfirm(true);
  };

  const submitBuy = () => {

    const msg = {
      type: "requestLafz",
      price: buyOfferValue,
      volume: buyVolume,
      dir: "buy",
      tablo: CURRENT_TABLO,
    };

    if (sendMessage(msg, socket)) {
      buyOfferTextChanged("");
      setBuyVolume(1);
      setNumpadBuy(false);
      setShowBuyConfirm(false);
    }
  };

  const [showSellConfirm, setShowSellConfirm] = useState(false);
  const [sellVolume, setSellVolume] = useState(1);

  const [myLafz, setMyLafz] = useAtom(myLafzTHFAtom)

  const sellClicked = () => {
    if (sellOfferText.length < 6) {
      setNumpadSell(true);
      return;
    }

    setNumpadSell(false);
    setShowSellConfirm(true);
  };

  const submitSell = () => {
    const msg = {
      type: "requestLafz",
      price: sellOfferValue,
      volume: sellVolume,
      dir: "sell",
      tablo: CURRENT_TABLO,
    };

    if (sendMessage(msg, socket)) {
      sellOfferTextChanged("");
      setSellVolume(1);
      setNumpadSell(false);
      setShowSellConfirm(false);
    }
  };

  const [clickedLafzSeq, setClickedLafzSeq] = useState(0);
  const lafzClicked = (lafz) => {
    if (!liveTablo.find((item) => item.seq === lafz.seq)) {
      return;
    }

    if (clickedLafzSeq === lafz.seq) {
      lafzDoubleClicked(lafz);
      setClickedLafzSeq(0);
    } else {
      setClickedLafzSeq(lafz.seq);
      setTimeout(() => {
        setClickedLafzSeq((prev) => 0);
      }, 500);
    }
  };

  const lafzDoubleClicked = (lafz) => {
    console.log(lafz);
  };

  const cancelLafz = (seq) => {
    sendMessage({type: "requestCancelLafz", tablo: CURRENT_TABLO,  seq: seq}, socket)
  }

  const volumeClicked = (kilo , item) =>
  {
    setClickedItem({kilo, item})
  }

  const sendDeal = () =>
  {
    console.log(clickedItem)
    setClickedItem(null)
  }

  useEffect(() => {
    if (!expandBottomBar) {
      setNumpadBuy(false);
      setNumpadSell(false);
    }
  }, [expandBottomBar]);
 
  return (
    <div className="w-full h-full flex flex-col">
      <div id="top-bar" className="w-full grow-0 shrink-0 h-[30px] flex flex-row text">
        <p
          className="flex justify-center items-center text-green-300 w-full border-b border-green-300 "
          style={{ backgroundColor: "rgba(0,40,0,0.1)" }}
        >
          {"خرید"}
        </p>
        <p
          className="flex justify-center items-center text-red-300 w-full border-b border-red-300 "
          style={{ backgroundColor: "rgba(40,0,0,0.1)" }}
        >
          {"فروش"}
        </p>
      </div>

      <div
        id="main-view"
        className="w-full h-full flex flex-row overflow-y-hidden grow-1"
      >
        <div className="w-full border-l border-gray-700 p-2 overflow-y-scroll">
          <div className="flex flex-col gap-2 w-full">
            {liveTabloAll
              .filter((item) => item.dir === "buy")
              .map((item, index) => (
                <LafzTablo
                  onClick={() => lafzClicked(item)}
                  onVolumeClicked={(kilo) => volumeClicked(kilo, item)}
                  liveTablo={liveTablo}
                  liveTabloOld={liveTabloOld}
                  item={item}
                  index={index}
                  now={now}
                />
              ))}
          </div>
        </div>

        <div className="w-full p-2 overflow-y-scroll">
          <div className="flex flex-col gap-2 w-full">
            {liveTabloAll
              .filter((item) => item.dir === "sell")
              .map((item, index) => (
                <LafzTablo
                  onClick={() => lafzClicked(item)}
                  onVolumeClicked={(kilo) => volumeClicked(kilo, item)}
                  liveTablo={liveTablo}
                  liveTabloOld={liveTabloOld}
                  item={item}
                  index={index}
                  now={now}
                />
              ))}
          </div>
        </div>
      </div>

      <div id="bottom-bar" 
        className={`${expandBottomBar ? "h-[400px] " : "h-[60px] "} transition-all duration-300 ease-in-out w-full flex flex-col grow-0 rounded-t-3xl border-t border-slate-400`}
        style={{ backgroundColor: "rgba(58,71,89,0.3)" }}
        >

        <div id="handle-bottom-bar" className={`flex justify-center items-center w-full py-1`}>
              <span className={`${expandBottomBar ? "-rotate-90 " : "rotate-90 "} ${numpadBuy || numpadSell ? "opacity-0  ": " "} transtion-all delay-300 duration-500 ease-in-out text-gray-400`} onClick={() => setExpandBottomBar(prev => !prev)}>
                <ArrowBackIosNewIcon/>
              </span>
        </div>   

        <div className={`text-white`}>
            {myLafz && <MyLafz item={myLafz} now={now} onCancel={() => cancelLafz(myLafz.seq)}/>}  
        </div>   

        <div
          className={`w-full flex flex-row ${expandBottomBar ? "opacity-100 " : "opacity-0 "}`}
        >
          <div
            className={`w-full h-full border-l border-gray-600 flex flex-col gap-4 px-1`}
          >
            <Numpad
              backgroundColor={"rgba(22, 74, 16, 0.4)"}
              numpadOpen={numpadBuy}
              setNumpadOpen={setNumpadBuy}
              text={buyOfferText}
              label={"پیشنهاد خرید شما (۵رقم)"}
              textChanged={buyOfferTextChanged}
            />

            <div className="w-full h-full flex flex-row-reverse items-center justify-center gap-2 ">
              {[1, 2, 3].map((kilo) => (
                <div
                  className={`flex justify-center items-center w-[48px] h-[40px] border rounded-lg transition-all duration-300 ease-in-out ${
                    kilo === buyVolume
                      ? "border-[#05ddfa] text-[#05ddfa] "
                      : "border-gray-400 text-gray-400 "
                  }`}
                  onClick={() => setBuyVolume(kilo)}
                >
                  <span>{`${kilo}`}</span>
                </div>
              ))}
            </div>

            <div className="flex justify-center items-center h-full w-full">
              <div
                onClick={buyClicked}
                className="w-[150px] py-3 flex items-center justify-center rounded-xl text-green-400 border border-green-500 bg-opacity-70 mb-[30px]"
              >
                {"ارسال خرید"}
              </div>
            </div>
          </div>

          <div className="w-full h-full flex flex-col px-1 gap-4">
            <Numpad
              backgroundColor={"rgba(107, 15, 15, 0.4)"}
              numpadOpen={numpadSell}
              setNumpadOpen={setNumpadSell}
              text={sellOfferText}
              label={"پیشنهاد فروش شما (۵رقم)"}
              textChanged={sellOfferTextChanged}
            />

            <div className="w-full h-full flex flex-row-reverse items-center justify-center gap-2 ">
              {[1, 2, 3].map((kilo) => (
                <div
                  className={`flex justify-center items-center w-[48px] h-[40px] border rounded-lg transition-all duration-300 ease-in-out ${
                    kilo === sellVolume
                      ? "border-[#05ddfa] text-[#05ddfa] "
                      : "border-gray-400 text-gray-400 "
                  }`}
                  onClick={() => setSellVolume(kilo)}
                >
                  <span>{`${kilo}`}</span>
                </div>
              ))}
            </div>

            <div className="flex justify-center items-center h-full w-full">
              <div
                onClick={sellClicked}
                className="w-[150px] py-3 flex items-center justify-center rounded-xl text-red-400 border border-red-500 bg-opacity-70 mb-[30px]"
              >
                {"ارسال فروش"}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={errorMessage?.length > 0}
        onClose={() => setErrorMesssage("")}
      >
        <DialogTitle
          sx={{ fontSize: "18px", color: "#ff4242" }}
          className="flex gap-1"
        >
          <ErrorOutlineIcon />
          {"خطا"}
        </DialogTitle>
        <DialogContent sx={{ color: "#ffeded" }}>{errorMessage}</DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorMesssage("")} color="white">
            بستن
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showBuyConfirm} onClose={() => setShowBuyConfirm(false)}>
        <DialogTitle sx={{ fontSize: "18px", color: "#dbdbdb" }}>
          <span className="text-green-500 font-bold">{" خرید "}</span>
          زیر مورد تایید است؟
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{
              color: "white",
              fontSize: "20px",
              borderBottom: "3px solid #00b530",
              textAlign: "center",
              paddingBottom: "2px",
            }}
          >
            <span>{`${buyVolume}`}</span>
            <span className="text-gray-300">{" کیلو "}</span>
            <span className="text-gray-300">{" در قیمت "}</span>
            <span>{`${buyOfferText}`}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ fontSize: "14px" }}
            color="white"
            onClick={() => setShowBuyConfirm(false)}
          >
            خیر انصراف میدهم
          </Button>
          <Button
            sx={{ fontSize: "14px" }}
            color="primary"
            variant="contained"
            onClick={submitBuy}
          >
            بله مورد تایید است
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showSellConfirm} onClose={() => setShowSellConfirm(false)}>
        <DialogTitle sx={{ fontSize: "18px", color: "#dbdbdb" }}>
          <span className="text-red-500 font-bold">{" فروش "}</span>
          زیر مورد تایید است؟
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{
              color: "white",
              fontSize: "20px",
              borderBottom: "3px solid #d60f0f",
              textAlign: "center",
              paddingBottom: "2px",
            }}
          >
            <span>{`${sellVolume}`}</span>
            <span className="text-gray-300">{" کیلو "}</span>
            <span className="text-gray-300">{" در قیمت "}</span>
            <span>{`${sellOfferText}`}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ fontSize: "14px" }}
            color="white"
            onClick={() => setShowSellConfirm(false)}
          >
            خیر انصراف میدهم
          </Button>
          <Button
            sx={{ fontSize: "14px" }}
            color="primary"
            variant="contained"
            onClick={submitSell}
          >
            بله مورد تایید است
          </Button>
        </DialogActions>
      </Dialog>


    
      <Dialog open={clickedItem !== null} onClose={() => setClickedItem(null)}>
        <DialogTitle sx={{ fontSize: "18px", color: "#dbdbdb" }}>
          <span>{" معامله "}</span>  
          <span className={`${clickedItem?.item?.dir === "sell" ? "text-green-500 ": "text-red-500 "} font-bold`}>{clickedItem?.item?.dir === "sell" ? " خرید " : " فروش "}</span>
          زیر مورد تایید است؟
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{
              color: "white",
              fontSize: "20px",
              borderBottom: `3px solid ${clickedItem?.item?.dir === "sell" ? "#00b530 ": "#d60f0f "}  `,
              textAlign: "center",
              paddingBottom: "2px",
            }}
          >
            <span>{`${clickedItem?.kilo || ""}`}</span>
            <span className="text-gray-300">{" کیلو "}</span>
            <span className="text-gray-300">{" در قیمت "}</span>
            <span>{`${clickedItem?.item?.price?.toLocaleString() || ""}`}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ fontSize: "14px" }}
            color="white"
            onClick={() => setClickedItem(null)}
          >
            خیر انصراف میدهم
          </Button>
          <Button
            sx={{ fontSize: "14px" }}
            color="primary"
            variant="contained"
            onClick={sendDeal}
          >
            بله مورد تایید است
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}

export default TabloHavaleFarda;
