import { CircularProgress } from "@mui/material";


function ConnectingView(){

    return (
      <div
        className="w-full h-screen fixed top-0 left-0 overflow-hidden flex justify-center items-center bg-black bg-opacity-50 text-white"
        style={{ zIndex: "900" }}
      >
        <span className="flex flex-col justify-center items-center gap-4 text-yellow-500">
          <CircularProgress color="inherit" />
          {/* <span className="text-sm">در حال اتصال...</span> */}
        </span>
      </div>
    );


}

export default ConnectingView