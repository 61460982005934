import { useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Alert,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  IconButton,
  InputAdornment,
  Snackbar,
} from "@mui/material";
import UserService from "../services/UserService";
import { useAtom } from "jotai";
import { accountAtom } from "../atoms/accountAtom";
import React, { useEffect, useState } from "react";
import TextBox from "./TextBox";
import { Visibility, VisibilityOff, VpnKeyOutlined } from "@mui/icons-material";
import { changePasswordSnackAtom } from "../atoms/changePasswordSnackAtom";

function ChangePasswordDialog({ open, onClose, closeDisable, currentPassword }) {
  const theme = useTheme();

  const [account, setAccount] = useAtom(accountAtom);

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  useEffect( () => {
    if (closeDisable && currentPassword)
    {
        setPassword(currentPassword)
    }

  }, [closeDisable, currentPassword])

  const closeDialog = () =>
  {
    setsubmitting(false)
    setPassword("")
    setNewPassword("")
    setNewPasswordConfirm("")

    setPasswordBlank(false);
    setnewPasswordBlank(false);
    setnewPasswordConfirmBlank(false);

    setInvalidPassword(false);
    setInvalidNewPassword(false);
    setInvalidNewPasswordSame(false);
    setSystemError(false);

    onClose()
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  const [submitting, setsubmitting] = useState(false);
  const [password, setPassword] = useState(currentPassword ?? "");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

  const [invalidPassword, setInvalidPassword] = useState(false);
  const [invalidNewPassword, setInvalidNewPassword] = useState(false);
  const [invalidNewPasswordSame, setInvalidNewPasswordSame] = useState(false);

  const [systemError, setSystemError] = useState(false);

  const [passwordBlank, setPasswordBlank] = useState(false);
  const [newpasswordBlank, setnewPasswordBlank] = useState(false);
  const [newpasswordConfirmBlank, setnewPasswordConfirmBlank] = useState(false);

  const [showSnackbar, setShowSnackBar] = useAtom(changePasswordSnackAtom)

  const submitChangePassword = async () => {
    if (submitting || !password || !newPassword || !newPasswordConfirm) {
      setPasswordBlank(!password);
      setnewPasswordBlank(!newPassword);
      setnewPasswordConfirmBlank(!newPasswordConfirm);
      return;
    }

    setPasswordBlank(false);
    setnewPasswordBlank(false);
    setnewPasswordConfirmBlank(false);



    setInvalidPassword(false);
    setInvalidNewPassword(false);
    setInvalidNewPasswordSame(false);
    setSystemError(false);

    setsubmitting(true);

    if (newPassword !== newPasswordConfirm || newPassword.length < 6) {
      setInvalidNewPassword(true);
      setsubmitting(false);
      return;
    }

    if (password === newPassword) {
      setInvalidNewPasswordSame(true);
      setsubmitting(false);
      return;
    }

    try {
      const result = await UserService.changepassword({
        username: account?.username,
        password,
        newPassword,
      });
      setsubmitting(false);
      if (result.data.status === "OK") {
        closeDialog();
        setShowSnackBar(true)
      } else {
        console.log(result.data);
        if (result.data.error === "NEW_PASSWORD_ERROR") {
          setInvalidNewPassword(true);
        } else {
          setInvalidPassword(true);
        }
      }
    } catch (err) {
      setsubmitting(false);
      setSystemError(true);
      console.log(err);
    }
  };

  return (
    <>
      <Dialog open={open} maxWidth="xs">
        <DialogTitle>
          <span
            className="text-lg text-center w-full flex justify-center items-center"
            style={{ color: theme.palette.primary.main }}
          >
            تغییر رمز عبور
          </span>
        </DialogTitle>
        <DialogContent dividers>
          <div className="flex flex-col justify-center items-center gap-8">
            <span className="opacity-80 flex flex-row w-full justify-between bg-gray-800 p-2">
              <span>نام کاربری </span>
              <span>{account?.username}</span>
            </span>

            <div className="flex flex-row items-end justify-center">
              <TextBox
                id="password"
                label="رمز عبور فعلی"
                type={showPassword ? "text" : "password"}
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="end">
                        <VpnKeyOutlined
                          style={{
                            color: theme.palette.primary.light,
                            width: "20px",
                          }}
                        />
                      </InputAdornment>
                    ),
                  },
                }}
                variant="standard"
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                  setPasswordBlank(!event.target.value);
                }}
                disabled={submitting || closeDisable}
                error={passwordBlank}
              />
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                onMouseUp={handleMouseUpPassword}
                edge="end"
                style={{ width: "10px" }}
                disabled={submitting}
              >
                {showPassword ? (
                  <VisibilityOff style={{ width: "22px" }} />
                ) : (
                  <Visibility style={{ width: "22px" }} />
                )}
              </IconButton>
            </div>

            <div className="flex flex-row items-end justify-center">
              <TextBox              
                id="new-password"
                label="رمز عبور جدید"
                type={showPassword ? "text" : "password"}
                autoFocus = {closeDisable}
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="end">
                        <VpnKeyOutlined
                          style={{
                            color: theme.palette.primary.light,
                            width: "20px",
                          }}
                        />
                      </InputAdornment>
                    ),
                  },
                }}
                variant="standard"
                value={newPassword}
                onChange={(event) => {
                  setNewPassword(event.target.value);
                  setnewPasswordBlank(!event.target.value);
                }}
                disabled={submitting}
                error={
                  newpasswordBlank ||
                  invalidNewPassword ||
                  invalidNewPasswordSame
                }
              />
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                onMouseUp={handleMouseUpPassword}
                edge="end"
                style={{ width: "10px" }}
                disabled={submitting}
              >
                {showPassword ? (
                  <VisibilityOff style={{ width: "22px" }} />
                ) : (
                  <Visibility style={{ width: "22px" }} />
                )}
              </IconButton>
            </div>

            <div className="flex flex-row items-end justify-center">
              <TextBox
                id="new-password-confirm"
                label="تکرار رمز عبور جدید"
                type={showPassword ? "text" : "password"}
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="end">
                        <VpnKeyOutlined
                          style={{
                            color: theme.palette.primary.light,
                            width: "20px",
                          }}
                        />
                      </InputAdornment>
                    ),
                  },
                }}
                variant="standard"
                value={newPasswordConfirm}
                onChange={(event) => {
                  setNewPasswordConfirm(event.target.value);
                  setnewPasswordConfirmBlank(!event.target.value);
                }}
                disabled={submitting}
                error={
                  newpasswordConfirmBlank ||
                  invalidNewPassword ||
                  invalidNewPasswordSame
                }
              />
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                onMouseUp={handleMouseUpPassword}
                edge="end"
                style={{ width: "10px" }}
                disabled={submitting}
              >
                {showPassword ? (
                  <VisibilityOff style={{ width: "22px" }} />
                ) : (
                  <Visibility style={{ width: "22px" }} />
                )}
              </IconButton>
            </div>

            <div className={`${invalidPassword ? " " : "hidden "}`}>
              <Alert severity="error">
                <p className="text-sm pr-2">رمزعبور فعلی اشتباه می باشد</p>
              </Alert>
            </div>

            <div className={`${invalidNewPassword ? " " : "hidden "}`}>
              <Alert severity="error">
                <p className="text-sm pr-2">
                  رمز عبور جدید یا تکرار آن صحیح نمی باشد
                </p>
              </Alert>
            </div>

            <div className={`${invalidNewPasswordSame ? " " : "hidden "}`}>
              <Alert severity="error">
                <p className="text-sm pr-2">
                  رمز عبور جدید با رمز عبور فعلی نباید یکسان باشد
                </p>
              </Alert>
            </div>

            <div className={`${systemError ? " " : "hidden "}`}>
              <Alert severity="error">
                <p className="text-xs pr-2">
                  <p className="mb-2">مشکل در ارتباط با سرور :</p>
                  <p></p>
                  لطفا اینترنت خود را مجددا کنترل نمایید
                </p>
              </Alert>
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          <div className="flex flex-row justify-between items-center w-full">
            {!closeDisable && (
              <Button color="text" autoFocus onClick={closeDialog}>
                بستن
              </Button>
            )}

            <Button
              variant="contained"
              color="primary"
              sx={{ width: "100%", height: "40px" }}
              onClick={() => submitChangePassword()}
            >
              {submitting ? (
                <span>
                  <CircularProgress
                    disableShrink
                    color="white"
                    style={{ color: `white`, marginTop: "7px" }}
                    size={30}
                  />
                </span>
              ) : (
                <span>تغییر رمز</span>
              )}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ChangePasswordDialog;
