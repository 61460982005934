import { atom} from 'jotai'
import { liveTabloTHFAtom } from './liveTabloTHFAtom'
import { accountAtom } from './accountAtom'



export const myLafzTHFAtom = atom( (get) => {
    const account = get(accountAtom)
    
    if (!account)
    {
        return null
    }

    const liveTablo = get(liveTabloTHFAtom)

    if (liveTablo)
    {
        return liveTablo.find(item => item.issuer === account.username)
    }

    return null

})