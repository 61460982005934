import { useAtom } from "jotai";
import { urlAtom } from "../atoms/urlAtom";
import { getAllPages } from "./_index";
import { useEffect, useState } from "react";

function Home() {
  const [url, setUrl] = useAtom(urlAtom);

  const [tabloList, setTabloList] = useState([])

  useEffect( () => {
    setTabloList(getAllPages().filter(item => item.type === "tablo"))
  }, [])

  return (
    <div className="w-full h-full flex flex-row justify-center items-start flex-wrap gap-4 pt-[50px]">

    {tabloList.map( (page,index) => (
        <div 
        key={`page-${index}`}
        className="flex justify-center items-center p-5 bg-slate-700 rounded-xl text-white h-[50px]"
        onClick={() => setUrl(page.url)}
        >
            {page.title}
        </div>

    ))}

    </div>
  );
}

export default Home;
