import { encrypt } from "./aes-wrapper"



export const sendMessage = (msg, socket) => {

    if (socket && socket.connected) {
        const msg_enc = encrypt(null, null, JSON.stringify(msg));
        socket.emit('message', msg_enc)
        return true
    }
    else {
        return false
    }
}
