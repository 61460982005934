export const encryptAES = (data, key, iv) => {
    const enc = new Uint8Array(data.length);
    for (let i = 0; i < data.length; i++) {
      enc[i] = 255 - data[i];
    }
  
    return enc;
  };
  
  export const decryptAES = (data, key, iv) => {
    const dec = new Uint8Array(data.length);
    for (let i = 0; i < data.length; i++) {
      dec[i] = 255 - data[i];
    }
    return dec;
  };
  