import dateFormat from "dateformat";
import AlarmOutlinedIcon from '@mui/icons-material/AlarmOutlined';
import { useAtom } from "jotai";
import { Button, CircularProgress } from "@mui/material";
import { useState } from "react";

function MyLafz({ item, now, onCancel }) {

  const [submitting , setsubmitting] = useState(false)

  const cancelClicked = () =>
  {
    if (submitting)
    {
      return
    }

    onCancel()
    setsubmitting(true)
    setTimeout(() => {
      setsubmitting(false)
    }, 5000);
  }

  return (
    <div
      key={`my-lafz-${item.seq}`}
      className={`py-1 px-2 w-full bg-[#000000] rounded-xl bg-opacity-50 transition-all duration-500 ease-in-out overflow-hidden flex flex-col gap-2 text-white`}
    >
      <div className="opacity-80 text-sm flex flex-row items-center justify-between">
        <span>
          {"سفارش فعال"}
        </span>

        <Button color="error" onClick={cancelClicked} >
          <span className={`${submitting ? "hidden " : " "}`}>
              {"لغو سفارش"}
          </span>

          <span className={`${submitting ? " " : "hidden "} flex justify-center items-center`}>
            <CircularProgress color="error" size={"28px"} />
          </span>
        </Button>

      </div>

      <div className="flex flex-row items-center gap-1 px-2">
        <span
          className={`${
            item.dir === "buy" ? "text-green-400 " : "text-red-400 "
          } text-sm`}
        >
          {`${item.dir === "buy" ? "خرید" : "فروش"}`}
        </span>

        <p className="text-left flex flex-row gap-1 w-fit px-4 items-center rounded-t-xl">
          <span>{`${item.volume || ""}`}</span>
          <span className="text-xs text-slate-300">{"کیلو"}</span>
        </p>


        <span className="flex flex-row gap-2 items-center">
        <span className="tracking-wide">
          {`${item.price?.toLocaleString() || ""}`}
        </span>
        <span className="text-xs text-slate-300">{"تومان"}</span>
      </span>


      </div>



      {item.timeStamp && (
        <div className="w-full h-[5px] bg-gray-400 flex justify-end rounded-xl">
          <div
            className="h-full rounded-xl bg-[#1cb2e8]"
            style={{
              width: `${
                (60 -
                  Math.round(
                    (now.getTime() - Date.parse(item.timeStamp)) / 1000
                  )) *
                1.66
              }%`,
            }}
          ></div>
        </div>
      )}
    </div>
  );
}

export default MyLafz;
