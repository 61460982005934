import {encryptAES, decryptAES} from "./aes";
import { base64ToBytes, bytesToBase64 } from "./base64-helper";

export const generateKey = () => {
  return generateRandomUint8Array(16);
};

export const generateIv = () => {
  return generateRandomUint8Array(16);
};

export const encrypt = (key, iv, text) => {
  try {
    const encrypted = encryptAES(stringToUnit8Array(text), key, {
      name: "AES-CBC",
      iv,
    });
    return bytesToBase64(encrypted);
  } catch (err) {
    throw new Error(`AES encryption failed! : ${err}`);
  }
};

export const decrypt = (key, iv, text_base64) => {
  try {
    const decrypted = decryptAES(base64ToBytes(text_base64), key, {
      name: "AES-CBC",
      iv,
    });
    return uint8ArrayToString(decrypted);
  } catch (err) {
    throw new Error(`AES decryption failed! : ${err}`);
  }
};

const generateRandomUint8Array = (len) => {
    const arr = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      arr[i] = Math.floor(Math.random() * 255) + 1;
    }
    return arr;
  };

const stringToUnit8Array = (text) => {
  return new TextEncoder("utf-8", { ignoreBOM: true }).encode(text);
};

const uint8ArrayToString = (arr) => {
  return new TextDecoder("utf-8", { ignoreBOM: true }).decode(arr);
};
