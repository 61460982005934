import React, { useEffect, useRef, useState } from "react";
import App from "./App";
import "@khmyznikov/pwa-install";
import { isMobile, isIOS, isAndroid } from "react-device-detect";
import IosShareIcon from "@mui/icons-material/IosShare";
import AddBoxIcon from "@mui/icons-material/AddBox";

import logo from "./assets/logo192.png";

function PreApp() {
  const awaitingPromiseRef = useRef();
  const deferredprompt = useRef(null);

  function isRunningStandalone() {
    return (
      window.matchMedia("(display-mode: standalone)").matches ||
      window.navigator.standalone
    );
  }

  const installApp = async () => {
    if (deferredprompt.current != null) {
      return deferredprompt.current
        .prompt()
        .then((event) => deferredprompt.current.userChoice)
        .then((choiceResult) => {
          if (choiceResult.outcome === "accepted") {
            if (awaitingPromiseRef.current) {
              awaitingPromiseRef.current.resolve();
            }
          } else {
            if (awaitingPromiseRef.current) {
              awaitingPromiseRef.current.reject();
            }
          }
        })
        .catch((err) => {
          if (awaitingPromiseRef.current) {
            awaitingPromiseRef.current.resolve();
          }
        });
    } else {
      if (awaitingPromiseRef.current) {
        awaitingPromiseRef.current.resolve();
      }
    }
  };

  const handleBeforeInstallPromptEvent = (event) => {
    event.preventDefault();
    deferredprompt.current = event;
  };

  useEffect(() => {
    window.addEventListener(
      "beforeinstallprompt",
      handleBeforeInstallPromptEvent
    );
    return function cleanup() {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPromptEvent
      );
    };
  }, []);

  return (
    <>
      {isMobile && isRunningStandalone() ? (
        <App />
      ) : (
        <div>
          {isMobile && (
            <div>
              {isIOS ? (
                <div
                  id="propmpt-ios-install"
                  className="w-full h-screen bg-[#000] flex flex-col gap-8 items-center justify-start text-white px-[30px]"
                >
                  <img src={logo} alt="logo" />
                  <p className="text-center text-lg leading-8">
                    {
                      "نسخه وب اپلیکیشن (PWA) بازار طلا را به صفحه اصلی اضافه کنید"
                    }
                  </p>

                  <p className="text-gray-300 text-sm text-center leading-5">
                    با این کار می توانید برای همیشه و بدون نیاز به بروزرسانی از
                    خدمات بازار طلا استفاده کنید
                  </p>

                  <p className="flex flex-row items-end justify-between w-full text-sm">
                    <p className="flex flex-row items-center">
                      <span>{"۱. روی دکمه "}</span>
                      <span className="px-2 text-yellow-300">{" Share "}</span>
                      <span>{"در نوار پایین کلیک کنید"}</span>
                    </p>
                    <p className="px-2 text-yellow-300">
                      <IosShareIcon />
                    </p>
                  </p>

                  <p className="flex flex-row items-end justify-between w-full text-sm">
                    <p className="flex flex-row items-center">
                      <span>{"۲. گزینه "}</span>
                      <span className="px-2 text-yellow-300">
                        {" Add to Home Screen "}
                      </span>
                      <span>{"را فعال کنید"}</span>
                    </p>
                    <p className="px-2 text-yellow-300">
                      <AddBoxIcon />
                    </p>
                  </p>

                  <p className="flex flex-row items-end justify-between w-full text-sm">
                    <p className="flex flex-row items-center">
                      <span>{"۲. در قسمت بالا روی "}</span>
                      <span className="px-2 text-yellow-300">{" Add "}</span>
                      <span>{"کلیک کنید"}</span>
                    </p>
                    <p className="px-2 text-yellow-300 font-bold">{"Add"}</p>
                  </p>
                </div>
              ) : (
                <div
                  id="propmpt-android-install"
                  className="w-full h-screen bg-[#000] flex flex-col gap-8 items-center justify-start text-white px-[30px]"
                >
                  <img src={logo} alt="logo" />
                  <p className="text-center text-lg leading-8">
                    {
                      "نسخه وب اپلیکیشن (PWA) بازار طلا را به صفحه اصلی اضافه کنید"
                    }
                  </p>

                  <p className="text-gray-300 text-sm text-center leading-5">
                    با این کار می توانید برای همیشه و بدون نیاز به بروزرسانی از
                    خدمات بازار طلا استفاده کنید
                  </p>

                  <p
                    className="rounded-xl bg-yellow-500 px-10 py-2 text-black text-xl mt-[50px]"
                    onClick={installApp}
                  >
                    نصب اپلیکیشن
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {/* <pwa-install></pwa-install> */}
    </>
  );
}

export default PreApp;
