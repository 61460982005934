import { useAtom } from "jotai";
import { urlAtom } from "../atoms/urlAtom";

function TabloHavaleRooz() {

 const [url, setUrl] = useAtom(urlAtom);

  return (
    <div className="w-full h-full flex justify-center items-center">

        <h1 className="text-3xl text-white">

            {`تابلوی حواله روز`}

        </h1>

    </div>
  );
}

export default TabloHavaleRooz;
