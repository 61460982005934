import { useAtom } from "jotai";
import { getPage, getAllPages } from "../pages/_index";

function RenderSwitch({ url }) {
  const pages = getAllPages();

  return (
    <>
      {pages.map((page, index) => (
        <div
          key={`page-render-${index}`}
          className={`${
            page.url === url
              ? "translate-x-0 opacity-100"
              : "translate-x-full opacity-0"
          } absolute top-0 left-0 w-full h-full transition-all duration-[0.3s] ease-in  `}
        >
          {page.component}
        </div>
      ))}
    </>
  );
}

export default RenderSwitch;
