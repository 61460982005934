import {
  AccountCircle,
  CloudOffOutlined,
  ElectricalServicesOutlined,
  Power,
  PowerOutlined,
  WifiOffOutlined,
  WifiOutlined,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useAtom } from "jotai";
import { socketAtom } from "../atoms/socketAtom";
import { useTheme } from "@mui/material/styles";
import { urlAtom } from "../atoms/urlAtom";
import { useEffect, useState } from "react";
import RenderSwitch from "./RenderSwitch";


function Main() {
  const theme = useTheme();
  const [socket, setSocket] = useAtom(socketAtom);

  const [url, setUrl] = useAtom(urlAtom)

  const [transition, setTransition] = useState(false)

  useEffect(() => {
    setTransition(true);
    setTimeout(() => {
      setTransition(false);
    }, 1000);
  }, [url]);


  return (
    <div
      className={`w-full h-full relative `}
    >

    <RenderSwitch url={url}/>

    </div>
  );
}

export default Main;
