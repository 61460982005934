import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import KeyboardHideIcon from "@mui/icons-material/KeyboardHide";
import { Button, IconButton } from "@mui/material";

function Numpad({
  backgroundColor,
  numpadOpen,
  setNumpadOpen,
  text,
  label,
  textChanged,
}) {
  return (
    <div
      className="relative w-full h-full flex flex-row justify-center items-center"
      style={{ direction: "ltr" }}
    >
      <div
        className="flex flex-col mx-2 w-full"
        // style={{ backgroundColor: backgroundColor }}
        onClick={() => setNumpadOpen((prev) => !prev)}
      >
        <div
          className={`w-full h-[40px] flex flex-row items-center justify-center gap-2 rounded border ${
            text.length > 0 && text.length < 6
              ? "border-red-500 "
              : "border-gray-400 "
          } `}
        >
          <span
            className={`${
              text.length > 0 ? "text-white text-xl " : "text-gray-400 "
            }`}
          >
            {`${text.length > 0 ? text : "XX,XXX"}`}
          </span>

          <span className="text-gray-300 text-lg">
            {`,000`}
            <span className="text-xs px-1 text-gray-300">{"تومان"}</span>
          </span>
        </div>
        <span
          style={{ direction: "rtl", textAlign: "left" }}
          className={`text-sm ${
            text.length > 0 && text.length < 6
              ? "text-red-500 "
              : "text-gray-400 "
          }  px-2 py-1`}
        >
          {`${label}`}
        </span>
      </div>

      <div
        className={`${
          numpadOpen ? "h-[250px] opacity-100 " : "h-0 opacity-100 "
        } flex flex-col overflow-hidden absolute w-full bottom-[72px] bg-slate-200 left-[0px] z-10 rounded-lg transition-all duration-300 ease-in-out`}
      >
        <div className="flex flex-row items-center justify-between w-full h-[50px] px-3">
          <span className="text-xl font-bold tracking-wide">{`${text}`}</span>
          <p className="flex flex-row items-center gap-3">
          <span className="text-xl">{",000"}</span>
          <IconButton
            sx={{ color: "#474747", width: "35px" }}
            onClick={() => setNumpadOpen(false)}
          >
            <KeyboardHideIcon />
          </IconButton>
          </p>
        </div>

        <div className="grid grid-cols-3 w-full h-full">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, -1, 0, -2].map((item) => (
            <>
              {item > -1 && (
                <Button
                  color="white"
                  className={`flex justify-center items-center w-full`}
                  sx={{ fontSize: "20px" }}
                  onClick={() => textChanged(`${text}${item}`)}
                >
                  {`${item}`}
                </Button>
              )}

              {item === -1 && (
                <Button
                  color="white"
                  className="flex justify-center items-center"
                  onClick={() => {
                    textChanged("");
                  }}
                >
                  <ClearIcon sx={{ width: "22px" }} />
                </Button>
              )}

              {item === -2 && (
                <Button
                  color="white"
                  className="flex justify-center items-center"
                  onClick={() =>
                    textChanged(text.substring(0, text.length - 1))
                  }
                >
                  <BackspaceOutlinedIcon sx={{ width: "22px" }} />
                </Button>
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Numpad;
