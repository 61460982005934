import API from './api';

export default class UserService {

    static signin = (payload) =>
    {
       return API.post('/api/user/signin', payload);
    }

    static changepassword = (payload) =>
    {
        return API.post('/api/user/changepassword', payload);
    }


}