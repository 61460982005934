import { useTheme } from "@emotion/react";
import { Key, Logout, Settings } from "@mui/icons-material";
import { Divider, ListItemIcon, Menu, MenuItem } from "@mui/material";
import ChangePasswordDialog from "./ChangePasswordDialog";
import { useState } from "react";

function AccountMenu({ anchorEl, open, onClose, account, submitLogout }) {

  const [changePasswordDialog, setChangePasswordDialog] = useState(false)

  const theme = useTheme();
  return (
    <>
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={onClose}
      onClick={onClose}
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            overflow: "visible",
            bgcolor: "background.menu",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.menu",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem>
        <span className="text-sm opacity-80 tracking-wider">
          {account?.username ?? ""}
        </span>
      </MenuItem>

      {account?.profileTitle?.length > 0 && (
        <MenuItem>
          <span className="text-sm opacity-80">{account.profileTitle}</span>
        </MenuItem>
      )}

      <Divider />

      {/* <MenuItem onClick={() => setChangePasswordDialog(true)}>
        <ListItemIcon>
          <Key fontSize="small" />
        </ListItemIcon>
        تغییر رمز
      </MenuItem> */}


      <MenuItem>
        <ListItemIcon>
          <Settings fontSize="small" />
        </ListItemIcon>
        تنظیمات
      </MenuItem>
      <MenuItem onClick={() => submitLogout()}>
        <ListItemIcon>
          <Logout fontSize="small" color="error" />
        </ListItemIcon>
        <span style={{ color: `${theme.palette.error.main}` }}> خروج </span>
      </MenuItem>
    </Menu>

    <ChangePasswordDialog open={changePasswordDialog} onClose={() => setChangePasswordDialog(false)}/>
    </>
  );
}

export default AccountMenu;
