import {  CloudDoneOutlined,  } from "@mui/icons-material";
import { useAtom } from "jotai";
import { useTheme } from "@mui/material/styles";
import CloudOffIcon from '@mui/icons-material/CloudOff';
import { useEffect, useState } from "react";
import { liveSocketAtom } from "../atoms/liveSocketAtom";

function OfflineAlert()
{
  const theme = useTheme();  

  const [liveSocket, setLiveSocket] = useAtom(liveSocketAtom)

  const [isOffline, setOffline] = useState(false)
  const [getOnline, setgetOnline] = useState(false)

  useEffect(() => {

    if (liveSocket)
    {
      setOffline((prev) => {
        if (prev)
        {
          setgetOnline(true)
          setTimeout(() => {
            setgetOnline(false)
          }, 3000);
        }
        return false
      })
    }

    setTimeout(() => {

      setOffline((prev) => {
        return !liveSocket
      })
    }, 5000);
    

  }, [liveSocket])

    return (
      <>
      <div className={`${getOnline || liveSocket ? "hidden " : " "} ${isOffline ? "h-[50px] opacity-100" : "h-0 opacity-0"} transition-all duration-300 ease-in-out w-full overflow-hidden bg-red-500 text-white flex flex-row items-center px-2 shadow-sm grow-0 gap-2`}>
        <CloudOffIcon/>
        <span>
          ارتباط شما با سرور قطع می باشد
        </span>
      </div>

      <div className={`${getOnline ? "h-[50px] opacity-100" : "h-0 opacity-0"} transition-all duration-300 ease-in-out w-full overflow-hidden bg-green-500 text-white flex flex-row items-center px-2 shadow-sm grow-0 gap-2`}>
        <CloudDoneOutlined/>
        <span>
          ارتباط شما با سرور مجددا برقرار گردید
        </span>
      </div>


      </>
    );
}

export default OfflineAlert